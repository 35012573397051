<template>
    <iframe data-tally-src="https://tally.so/r/mZz5za" width="100%" height="100%" frameborder="0" marginheight="0"
        marginwidth="0" title="Pawhoo Waitlist"></iframe>
</template>

<script setup>
import { onMounted } from 'vue';


onMounted(() => {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.async = true;
    document.head.appendChild(script);
})

</script>

<style scoped>
iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0;
}
</style>