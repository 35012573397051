<template>
    <div class="parent">
        <div class="terms-and-conditions">
            <img class="paw-logo" :src="require('@/assets/pawq.svg')" alt="logo" />
            <h1>Terms and Conditions</h1>
            <p><strong>Effective Date:</strong>16th October, 2024</p>
            <p>Welcome to Pawhoo! By accessing or using our website, you agree to be bound by these Terms and
                Conditions.
                Please read them carefully before using our services.</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing or using Pawhoo, you agree to comply with and be legally bound by these Terms and
                Conditions. If
                you do not agree to these terms, please do not use our website.</p>

            <h2>2. Services Provided</h2>
            <p>Pawhoo offers a platform for pet adoption, listings, and related services, including connecting users
                with
                vets, pet shops, and stores, as well as maintaining health records for pets. We do not own, control, or
                guarantee any pets listed for adoption, or services offered by third-party vendors on our platform.</p>

            <h2>3. User Registration</h2>
            <p>To access certain features of Pawhoo, you may be required to create an account. You agree to provide
                accurate
                and complete information during the registration process and to keep your account information
                up-to-date.
                You are responsible for maintaining the confidentiality of your login credentials and for all activities
                that occur under your account.</p>

            <h2>4. User Conduct</h2>
            <p>While using Pawhoo, you agree not to:</p>
            <ul>
                <li>Violate any local, state, national, or international law.</li>
                <li>Post any inaccurate, incomplete, or misleading information, especially regarding pets for adoption.
                </li>
                <li>Engage in any activity that is harmful, abusive, or otherwise objectionable, including harassment or
                    discrimination.</li>
                <li>Attempt to gain unauthorized access to our systems, website, or user accounts.</li>
            </ul>

            <h2>5. Listings and Adoptions</h2>
            <p>Pawhoo does not guarantee the accuracy of listings or the availability of pets. All adoptions and
                interactions between users and pet owners are at the discretion of the involved parties. Pawhoo is not
                responsible for any disputes that may arise.</p>

            <h2>6. Health Records and Services</h2>
            <p>Health records maintained on Pawhoo are user-provided and intended for informational purposes only. We
                are
                not responsible for the accuracy or completeness of any health information. Consult a veterinarian for
                accurate health guidance and advice.</p>

            <h2>7. Third-Party Services</h2>
            <p>Pawhoo may include links to third-party services such as vets, pet shops, and stores. We do not endorse
                or
                assume any responsibility for the accuracy, quality, or availability of these third-party services.
                Interactions with third-party services are at your own risk.</p>

            <h2>8. Fees and Payments</h2>
            <p>Some features of Pawhoo may require payment. By using these paid features, you agree to pay all fees
                associated with your account. We reserve the right to change the pricing for paid services at any time.
                All
                payments are non-refundable, unless otherwise specified.</p>

            <h2>9. Intellectual Property</h2>
            <p>All content on Pawhoo, including text, graphics, logos, images, and software, is the property of Pawhoo
                or
                its licensors and is protected by intellectual property laws. You agree not to use any content for
                commercial purposes without our explicit permission.</p>

            <h2>10. Limitation of Liability</h2>
            <p>Pawhoo is not liable for any direct, indirect, incidental, or consequential damages that result from the
                use
                of, or inability to use, our services. This includes, but is not limited to, damages for loss of
                profits,
                goodwill, data, or other intangible losses.</p>

            <h2>11. Indemnification</h2>
            <p>You agree to indemnify and hold Pawhoo, its affiliates, and their respective officers, directors, agents,
                and
                employees harmless from any claims, liabilities, damages, losses, and expenses, including legal fees,
                arising out of your use of the website, your violation of these terms, or your infringement of any
                rights of
                another.</p>

            <h2>12. Termination</h2>
            <p>We reserve the right to terminate or suspend your account and access to Pawhoo at our discretion, without
                notice, for conduct that we believe violates these Terms and Conditions, or is harmful to our users, us,
                or
                third parties, or for any other reason.</p>

            <h2>13. Changes to Terms and Conditions</h2>
            <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and
                if
                significant, we will notify you via email or a notice on our website. By continuing to use our services
                after any updates, you accept the new terms.</p>

            <h2>14. Governing Law</h2>
            <p>These Terms and Conditions are governed by and construed in accordance with the laws of Nigeria, without regard to its conflict of law principles.</p>

            <h2>15. Contact Us</h2>
            <p>If you have any questions about these Terms and Conditions, please contact us:</p>
            <p>
                <strong>Pawhoo Team</strong><br />
                Email: hey@tackstry.com<br />
            </p>
            <p>By using Pawhoo, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
            </p>
        </div>
    </div>

</template>

<script>
export default {
    name: "TermsAndConditions",
};
</script>

<style scoped>
.parent {
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
}

.terms-and-conditions {
    padding: 20px;
    overflow: hidden;
    max-width: 1000px;
    justify-self: center;
}

.paw-logo {
  height: 60px;
  margin-top: 15px;
}


.terms-and-conditions h1,
.terms-and-conditions h2 {
    color: #333;
}

.terms-and-conditions p,
.terms-and-conditions ul {
    line-height: 1.6;
    color: #555;
}

.terms-and-conditions ul {
    padding-left: 20px;
}
</style>