<template>
  <div class="main-container">
    <div class="navbar">
      <img class="paw-logo" :src="require('@/assets/pawq.svg')" alt="logo" />
      <!-- <div class="get-app-div" @click="scrollToDownloadDiv()" style="cursor: pointer;">
        <p>GET APP</p>
        <div ref="downloadArrow">
          <img :src="require('@/assets/arrow_down.svg')" alt="scroll to download"
            style="height: 20px; margin-left: 10px;" />
        </div>
      </div> -->
    </div>

    <div class="dog-section">
      <div class="dog-section--part1">
        <h1 class="dog-section--discover-text">
          LOOKING FOR <br>A <span class="colored-text">FURRY</span> <br>FRIEND? <br>LOOK NO FURTHER
        </h1>
        <p class="dog-section--discover-desc">
          Find your perfect furry companion through pet adoption<br />
          and experience the magic of companionship.
        </p>
        <button class="rounded-download-button" @click="joinWaitlist()">Join the waitlist</button>
      </div>
      <img class="vet-image" :src="require('@/assets/main3.jpg')" alt="man with white dog" />
      <div style="width: 4%;"></div>
      <!-- <img class="cat-dog-phone" :src="require('@/assets/Union.png')" alt="dogs and phone" /> -->
    </div>

    <div class="best-app-section">
      <img class="man-white-dog" :src="require('@/assets/whitebrown.jpg')" alt="man with white dog" />
      <div style="width: 15%;"></div>
      <div style="display: flex; flex-direction: column; justify-content: center;">
        <h1 class="best-app-text">
          <span class="colored-text">PAWHOO</span> <br>IS THE BEST <span class="colored-text">APP</span> TO GET
          YOUR NEW PET
        </h1>
        <p class="best-app-desc">
          Dive into detailed profiles of each pet, complete with photos, descriptions, and
          behavioral traits. Learn about their personalities, likes, and dislikes to
          ensure a seamless transition into your home.
        </p>
      </div>

    </div>

    <div class="vet-section">
      <div class="vet-section-part1">
        <h1 class="connect-with-vet-text">
          CONNECT WITH REPUTABLE<br />
          <span class="colored-text">VETS</span> AND <span class="colored-text">STORES</span>
        </h1>
        <p class="connect-with-vet-desc">
          Pawhoo partners with reputable vets and stores nationwide to connect you with
          reliable veterinary care and premium pet products, saving you time and effort
          in sourcing these services and items independently.
        </p>
      </div>
      <div style="width: 15%;"></div>
      <img class="vet-image" :src="require('@/assets/vet.jpg')" alt="man with white dog" />
    </div>

    <div class="gallery-section">
      <h1 ref="gettingPetText" class="getting-a-pet-text">
        GETTING A NEW PET HAS NEVER BEEN THIS EASY
      </h1>
    </div>

    <!-- <div class="try-pawhoo-section">
      <div class="try-pawhoo-grey-bg">
        <img class="try-pawhoo-free-img" :src="require('@/assets/trypawhoofree.svg')" alt="Try Pawhoo" />
        <img class="try-pawhoo-free-mobile-img" :src="require('@/assets/trypawhoomobile.svg')" alt="Try Pawhoo" />
        <p id="tryPawhoo" class="try-pawhoo-free-text">
          TRY PAWHOO FOR FREE<br />
          ON IOS AND ANDROID
        </p>
        <div id="storeButtons" class="store-buttons">
          <img class="playstore" :src="require('@/assets/playstore.svg')" alt="playstore" />
          <div class="store-separator"></div>
          <img class="appstore" :src="require('@/assets/appstore.svg')" alt="appstore" />
        </div>
      </div>
    </div> -->

    <div class="footer-section">
      <div class="footer-info">
        <div class="footer-info-block">
          <p style="font-size: 24px; font-weight: 800">Pawhoo</p>
          <p style="line-height: 30px">Stay updated by signing up for our newsletter</p>
          <form @submit.prevent="" style="margin-top: 20px">
            <div class="newsletter-block">
              <input class="email-input" type="email" id="email" name="email" placeholder="Enter your email address" />
              <button class="submit-button" type="submit" @click="subscribeToNewsLetter()">
                Subscribe now
              </button>
            </div>
          </form>
        </div>

        <div class="company-info-blocks">
          <div class="info-block">
            <p class="info-header">Company</p>
            <p class="info-title" @click="openTackstry()">About us</p>
            <p class="info-title" @click="openTackstry()">Our Team</p>
            <p class="info-title" @click="openTackstry()">Contact us</p>
          </div>
          <div class="info-block">
            <p class="info-header">Socials</p>
            <p class="info-title">Twitter</p>
            <p class="info-title">Facebook</p>
            <p class="info-title">Instagram</p>
            <p class="info-title">LinkedIn</p>
          </div>
          <div class="info-block">
            <p class="info-header">Help</p>
            <p class="info-title" @click="navigateToPrivacy">Privacy Policy</p>
            <p class="info-title" @click="navigateToTerms">Terms & Condition</p>
          </div>
        </div>
      </div>

      <p>Pawhoo ©{{ currentYear }}. All Rights Reserved</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { gsap } from "gsap";
import { ref } from "vue";
import { toast } from "vue3-toastify/dist/index";
import 'vue3-toastify/dist/index.css';
import axios from "axios";
import { useRouter } from "vue-router";


// const downloadArrow = ref(null);
const gettingPetText = ref(null);
const currentYear = ref(getCurrentYear());

const notifyMessage = ref("")

const router = useRouter()

var notify = () => {
  toast(notifyMessage.value, {
    autoClose: 1000,
    theme: "dark",
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
  });
}


const navigateToPrivacy = () => {
  router.push("/privacy")
}

const navigateToTerms = () => {
  router.push("/terms")
}

function getCurrentYear() {
  return new Date().getFullYear();
}

function joinWaitlist() {
  router.push("/waitlist")
}

// function scrollToDownloadDiv() {
//   const target = document.getElementById('tryPawhoo');
//   if (target) {
//     target.scrollIntoView({ behavior: 'smooth' });
//   }
// }

function subscribeToNewsLetter() {
  const emailInput = document.getElementById("email");
  const email = emailInput.value;

  if (email) {
    if (!emailInput.checkValidity()) {
      return;
    }

    axios.post("https://pawhoo-backend.onrender.com/api/newsletter/subscribe", {
      email: email
    })
      .then(function (response) {
        notifyMessage.value = response.data.message
        notify()
      })
      .catch(function (error) {
        notifyMessage.value = error.response.data.error
        notify()
      });

  } else {
    notifyMessage.value = "Email adress is empty"
    notify()
  }


}

function openTackstry() {
  window.open("https://www.tackstry.com", '_blank');
}

onMounted(() => {

  // gsap.to(downloadArrow.value, {
  //   y: 5,
  //   duration: 0.5,
  //   ease: "power1.inOut",
  //   yoyo: true,
  //   repeat: -1,
  // });

  gsap.to(gettingPetText.value, {
    xPercent: -100,
    ease: "none",
    duration: 15,
    repeat: -1,
  });


});
</script>

<style scoped>
/* @font-face { */
  /* font-family: "Brice";
  src: url("@/fonts/brice.ttf") format("truetype");
  font-display: block; */
/* } */

/* @font-face { */
  /* font-family: "Inter"; */
  /* src: url("@/fonts/gilroymedium.ttf") format("truetype");
  font-display: block; */
/* } */

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  will-change: scroll-position;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.paw-logo {
  /* width: 70px; */
  height: 60px;
  margin-top: 15px;
  margin-left: 5%;
}

@media (max-width: 560px) {
  .paw-logo {
    width: 36px;
    height: 30px;
    /* margin-top: -2px; */
    margin-bottom: 0px;
  }
}

@media (min-width: 561px) and (max-width: 768px) {
  .paw-logo {
    width: 50px;
    height: 40px;
    margin-bottom: 10px;
  }
}

.get-app-div {
  display: flex;
  align-items: center;
  margin-right: 5%;
  z-index: 1;
}

.star {
  height: 40px;
  width: 40px;
  position: relative;
  left: 44%;
  bottom: 1%;
}

@media (max-width: 560px) {
  .star {
    left: 10%;
    top: 150px;
  }
}

@media (min-width: 561px) and (max-width: 768px) {
  .star {
    left: 5%;
    top: 200px;
  }
}

.star1 {
  height: 40px;
  width: 40px;
  position: relative;
  right: 5%;
  top: 5%;
}


@media (max-width: 768px) {
  .star1 {
    left: 5%;
  }
}

.star2 {
  height: 40px;
  width: 40px;
  position: relative;
  right: 13%;
  bottom: 1%;
}

@media (max-width: 450px) {
  .star2 {
    left: 85%;
    bottom: 630px;
  }
}

@media (min-width: 450px) and (max-width: 720px) {
  .star2 {
    left: 85%;
    bottom: 600px;
  }
}

@media (min-width: 720px) and (max-width: 780px) {
  .star2 {
    left: 85%;
    bottom: 690px;
  }
}


.dog-section {
  display: flex;
  width: 100%;
  height: 80vh;
  flex-direction: row;
  padding-top: 30px;
}

@media (max-width: 768px) {
  .dog-section {
    flex-direction: column;
    height: auto;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .dog-section {
    height: auto;
  }
}

.dog-section--part1 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 5%;
}

@media (max-width: 768px) {
  .dog-section--part1 {
    align-self: center;
    padding-inline: 5%;
  }
}

@media (min-width: 769px) and (max-width: 1400px) {
  .dog-section--part1 {
    padding-left: 60px;
  }
}

.dog-section--discover-text {
  position: relative;
  top: 4%;
  font-size: 70px;
  color: black;
  font-family: "Permanent Marker";
}

@media (max-width: 768px) {
  .dog-section--discover-text {
    position: relative;
    align-self: center;
    font-size: 30px;
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .dog-section--discover-text {
    font-size: 50px;
  }
}

.dog-section--discover-desc {
  margin-top: 20px;
  font-size: 22px;
}

@media (max-width: 768px) {
  .dog-section--discover-desc {
    align-self: center;
    text-align: center;
  }
}

.cat-dog-phone {
  width: 45%;
  scale: 1;
  height: fit-content;
  align-self: center;
  object-fit: cover;
  padding-right: 30px;
}

@media (max-width: 768px) {
  .cat-dog-phone {
    align-self: center;
    width: 100%;
    /* scale: 1.3; */
    height: fit-content;
    margin-top: 40px;
    padding-right: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .cat-dog-phone {
    width: 40%;
    align-self: center;
    height: fit-content;
    /* scale: 2; */
    object-fit: contain;
  }
}


@media (min-width: 1201px) and (max-width: 1350px) {
  .cat-dog-phone {
    width: 45%;
    /* scale: 1.8; */
    align-self: center;
    object-fit: cover;
    padding-right: 30px;
  }
}

.colored-text {
  /* color: #fc924e; */
  color: #035e9f;
}

.rounded-download-button {
  background-color: #003459;
  border: none;
  color: white;
  padding: 20px 32px;
  font-weight: bold;
  position: relative;
  padding-inline: 70px;
  bottom: 20px;
  text-align: center;
  margin-top: 30px;
  text-decoration: none;
  font-size: 22px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  
}

.rounded-download-button:hover {
    background-color: #0d5c94;
    /* color: black; */
  }

@media (max-width: 768px) {
  .rounded-download-button {
    padding: 15px 32px;
    align-self: center;
    cursor: none;
    font-size: 16px;
  }
}

.rounded-download-button:active {
  background-color: #000;
  color: white;
}

.best-app-section {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
}

@media (max-width: 768px) {
  .best-app-section {
    flex-direction: column;
    margin-top: 50px;
    flex-direction: column-reverse;
  }
}

.best-app-text {
  font-family: 'Permanent Marker';
  font-size: 70px;
  color: black;
}

@media (max-width: 768px) {
  .best-app-text {
    align-self: center;
    text-align: center;
    padding-inline: 5%;
    font-size: 30px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .best-app-text {
    font-size: 50px;
  }
}

.best-app-desc {
  font-size: 22px;
  margin-right: 20%;
  margin-top: 0px;
}

@media (max-width: 768px) {
  .best-app-desc {
    margin-right: 0%;
    padding-inline: 30px;
    margin-top: 0px;
    align-self: center;
    text-align: center;
    font-size: 18px;
  }
}

.man-white-dog {
  width: 100%;
  mask-image: url(@/assets/petals.svg);
  -webkit-mask-image: url(@/assets/petals.svg);
  mask-size: 100%;
  align-self: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  object-fit: fill;
}

@media (max-width: 768px) {
  .man-white-dog {
    align-self: center;
    width: 90%;
    margin-top: 20px;
  }
}

.vet-section {
  margin-top: 30px;
  display: flex;
  align-items: center;
  width: 90%;
}

@media (max-width: 768px) {
  .vet-section {
    flex-direction: column;
    margin-top: 0px;
  }
}

.vet-section-part1 {
  display: flex;
  flex-direction: column;
  width: 40%;
}

@media (max-width: 768px) {
  .vet-section-part1 {
    width: 100%;
    align-self: center;
  }
}

.connect-with-vet-text {
  font-family: 'Permanent Marker';
  font-size: 70px;
  color: black;
}

@media (max-width: 768px) {
  .connect-with-vet-text {
    font-size: 30px;
    text-align: center;
    align-self: center;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .connect-with-vet-text {
    font-size: 50px;
  }
}


.connect-with-vet-desc {
  font-size: 22px;
  margin-top: -30px;
}

@media (max-width: 768px) {
  .connect-with-vet-desc {
    font-size: 18px;
    text-align: center;
    margin-top: 0px;
  }
}

.vet-image {
  width: 45%;
  mask-size: 100%;
  align-self: center;
  mask-image: url(@/assets/petals.svg);
  -webkit-mask-image: url(@/assets/petals.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  object-fit: fill;
}

@media (max-width: 768px) {
  .vet-image {
    width: 90%;
    margin-top: 20px;
    align-self: center;
  }
}

.gallery-section {
  margin-top: 130px;
  width: 100%;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
}

.getting-a-pet-text {
  font-family: 'Permanent Marker';
  font-size: 20vh;
  color: #035e9f;
  margin-top: 0px;
  display: inline-block;
  padding-left: 100%;
  will-change: transform;
}

@media (max-width: 768px) {
  .getting-a-pet-text {
    font-size: 10vh;
    padding-inline: 40px;
  }
}

.try-pawhoo-section {
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
}

@media (max-width: 768px) {
  .try-pawhoo-section {
    margin-top: 40px;
  }
}

.try-pawhoo-grey-bg {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding-top: 30px;
  padding-bottom: 70px;
  padding-left: 50px;
  padding-right: 70px;
  z-index: 1;
  border-radius: 48px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .try-pawhoo-grey-bg {
    padding-top: 20px;
    width: 70%;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
  }
}

.try-pawhoo-free-img {
  z-index: 1;
}

@media (max-width: 768px) {
  .try-pawhoo-free-img {
    display: none;
  }
}

.try-pawhoo-free-mobile-img {
  display: none;
}

@media (max-width: 768px) {
  .try-pawhoo-free-mobile-img {
    display: block;
    z-index: 1;
    align-self: center;
    width: 120%;
    border-radius: 10px;
  }
}

.try-pawhoo-free-text {
  font-family: 'Permanent Marker';
  font-size: 50px;
  color: black;
}

@media (max-width: 768px) {
  .try-pawhoo-free-text {
    font-size: 30px;
    margin-top: 0px;
    align-self: center;
    text-align: center;
  }
}

.store-buttons {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .store-buttons {
    margin-top: 0px;
    flex-direction: column;
    margin-bottom: 0px;
    justify-content: center;
  }
}

.playstore {
  height: 50px;
  border-radius: 5px;
  scale: 1.4;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.playstore:hover {
  cursor: pointer;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .playstore {
    width: 200px;
    scale: 1;
    height: auto;
  }
}

.appstore {
  height: 50px;
  border-radius: 5px;
  scale: 1.4;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.appstore:hover {
  cursor: pointer;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .appstore {
    width: 200px;
    scale: 1;
    height: auto;
  }
}

.store-separator {
  width: 15%;
}

@media (max-width: 768px) {
  .store-separator {
    height: 15px;
  }
}

.footer-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 80px;
}

.footer-info {
  display: flex;
  flex-direction: row;
  width: 80%;
}

@media (max-width: 768px) {
  .footer-info {
    flex-direction: column;
  }
}

.footer-info-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 200px;
}

@media (max-width: 768px) {
  .footer-info-block {
    margin-bottom: 0px;
    margin-top: -20px;
  }
}

.newsletter-block {
  background-color: #003459;
  border-radius: 40px;
  padding: 2px;
  width: 60%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .newsletter-block {
    width: 100%;
    margin-top: -10px;
  }
}

.email-input {
  border: none;
  background-color: #003459;
  border-top-left-radius: 40px;
  color: #fff;
  font-weight: bold;
  border-bottom-left-radius: 40px;
  padding-left: 30px;
  width: 70%;
  padding-right: 20px;
  flex-grow: 1;
  font-size: 20px;
  outline: none;
}

@media (max-width: 768px) {
  .email-input {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.submit-button {
  border: none;
  padding: 20px;
  font-size: 20px;
  width: 300px;
  font-weight: 900;
  border-radius: 40px;
  cursor: pointer;
  color: #003459;
  background-color: white;
}

@media (max-width: 768px) {
  .submit-button {
    font-size: 16px;
    width: 240px;
    padding-inline: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.company-info-blocks {
  display: flex;
  flex-direction: row;
  width: 45%;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .company-info-blocks {
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.info-block {
  display: flex;
  flex-direction: column;
  width: 15%
}

.info-header {
  font-size: 20px;
  font-weight: 800;
}

@media (max-width: 768px) {
  .info-header {
    font-size: 15px;
  }
}

.info-title {
  margin-top: 0px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .info-title {
    font-size: 13px;
  }
}

input[type="email"]::placeholder {
  color: #fff;
}
</style>
