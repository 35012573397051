import { createRouter, createWebHistory } from 'vue-router'
import MainView from '@/views/MainView.vue'
import WaitlistForm from '@/views/WaitlistForm.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import TermsView from '@/views/TermsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/waitlist',
    name: 'waitlist',
    component: WaitlistForm
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPolicyView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
